import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button, Alert } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';
import { ItemViewer } from 'pages';

export default class GetGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            group: { members: [], creator: {}, name: '' },
            restaurant_list: [],
            copied: false,
        };

        this.copy_link = this.copy_link.bind(this);
        this.get_group = this.get_group.bind(this);
        this.load_group = this.load_group.bind(this);
    }

    componentDidMount() {
        this.get_group();
    }

    get_group() {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'GET',
            '/api/get_group/',
            { id: params['group_id'] },
            this.load_group,
        );
    }

    load_group(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    copy_link() {
        navigator.clipboard.writeText(
            'https://thefeast.food/join_group/' + this.state.group.id,
        );
        this.setState({ copied: true });
    }

    render() {
        let copied = null;
        if (this.state.copied) {
            copied = <Alert type="success">Link Copied</Alert>;
        }

        let member_list = [];
        for (let member of this.state.group.members) {
            member_list.push(<p>{member.email}</p>);
        }

        let recommended_restaurants = [];
        for (let restaurant of this.state.restaurant_list) {
            let example = (
                <div>
                    <p>{restaurant.description}</p>
                    <Button
                        type="success"
                        href={`/restaurant_viewer/${restaurant.id}/`}
                    >
                        Find Something To Eat Here.
                    </Button>
                </div>
            );

            if (restaurant.user_like) {
                example = (
                    <ItemViewer
                        image_only={true}
                        menu_item={restaurant.user_like}
                        restaurant={null}
                        className="col-12"
                    />
                );
            }

            recommended_restaurants.push(
                <div className="col-md-4 col-sm-12">
                    <h4>
                        <a href={`/restaurant_viewer/${restaurant.id}/`}>
                            {restaurant.name}
                        </a>
                        ({restaurant.likes.length}/
                        {this.state.group.members.length + 1} members)
                    </h4>
                    <div className="row">{example}</div>
                </div>,
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div>
                        <h4>{this.state.group.name}</h4>
                        <p>Creator: {this.state.group.creator.email}</p>
                        <Button
                            type="primary"
                            href={`/menu_item_list/${this.state.group.id}/`}
                        >
                            Discover What To Eat
                        </Button>

                        <h5 style={{ marginTop: '50px' }}>
                            Recommendations for your group:
                        </h5>
                        <div className="row">{recommended_restaurants}</div>

                        <h5 style={{ marginTop: '50px' }}>Members</h5>
                        <Button type="success" onClick={this.copy_link}>
                            Copy Sharable Link
                            <i
                                class="bi bi-copy"
                                style={{ marginLeft: '5px' }}
                            ></i>
                        </Button>
                        {copied}
                        {member_list}
                    </div>
                </Container>
            </Loading>
        );
    }
}
