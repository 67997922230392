import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
const GOOGLE_CLIENT_ID =
    '18475095952-jo6vds2rr6q58np399jg9m0f0qm40fs9.apps.googleusercontent.com';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: null,
            error: '',
            data: {},

            waiting_for_response: false,
        };

        this.submit = this.submit.bind(this);
        this.login_callback = this.login_callback.bind(this);
        this.login = this.login.bind(this);
        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);
    }

    componentDidMount() {
        if (window.secret_react_state.user) {
            window.location.href = '/menu_item_list/';
        }

        let client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'email',
            callback: (response) => this.google_login(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client: client });
    }

    submit(state) {
        let data = {
            email: state['email'],
            password: state['password'],
        };
        data.email = data.email.toLowerCase();

        ajax_wrapper('POST', '/user/token/', data, this.login_callback);
    }

    login(state) {
        let error = '';
        // Validate for before submitting
        if (!state['email']) {
            error = 'Email field is missing';
        } else if (!state['password']) {
            error = 'Password field is missing';
        }

        // Anti-mash check
        if (this.state.waiting_for_response) {
            return;
        }

        // Set the state before triggering request to prevent duplication
        this.setState(
            { waiting_for_response: true },
            function () {
                ajax_wrapper(
                    'POST',
                    '/user/token/',
                    {
                        email: state['email'],
                        password: state['password'],
                    },
                    this.login_callback,
                );
            }.bind(this),
        );
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper(
                'POST',
                '/user/google_login/',
                state,
                this.login_callback,
            );
        }
    }

    google_error(state) {
        console.log('Google Error', state);
        this.setState({ error: state['message'] });
    }

    login_callback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            let params = get_global_state()['params'];
            let group_id = params['group_id'];
            if (group_id) {
                window.location.href = `/join_group/${group_id}`;
            } else if (localStorage.getItem('redirect')) {
                let url = localStorage.getItem('redirect');
                localStorage.removeItem('redirect');
                window.location.href = url;
            } else {
                window.location.href = '/menu_item_list/';
            }
        }
    }

    render() {
        let error = null;
        if (this.state.error !== '') {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ border: '1px solid black' }}
                >
                    <div
                        style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            lineHeight: '20px',
                        }}
                    >
                        {'Login with Google'}
                    </div>
                    <img
                        style={{
                            width: '20px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        return (
            <Container>
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-md-4 col-sm-12"></div>
                    <div className="col-md-4 col-sm-12">
                        <div style={{ textAlign: 'center' }}>
                            {google_button}
                        </div>

                        <div
                            style={{
                                textAlign: 'center',
                                display: 'block',
                                margin: '30px',
                            }}
                        >
                            OR
                        </div>
                        <Form submit={this.submit} submit_text="Submit">
                            <TextInput
                                name="email"
                                label="Email"
                                required={true}
                            />
                            <TextInput
                                type="password"
                                name="password"
                                label="Password"
                                required={true}
                            />
                        </Form>
                        {error}
                    </div>
                    <div className="col-4 col-sm-12"></div>
                </div>
            </Container>
        );
    }
}

export default Login;
