import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button, Alert } from 'library';
import { ajax_wrapper } from 'functions';

class Group extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ padding: '20px' }}>
                <a href={'/view_group/' + this.props.group.id + '/'}>
                    <h4>{this.props.group.name}</h4>
                </a>
            </div>
        );
    }
}

export default class UserGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            created_groups: [],
            member_of_groups: [],

            loaded: false,
        };

        this.get_user_groups = this.get_user_groups.bind(this);
        this.load_user_groups = this.load_user_groups.bind(this);
    }

    componentDidMount() {
        this.get_user_groups();
    }

    get_user_groups() {
        ajax_wrapper('GET', '/api/user_groups/', {}, this.load_user_groups);
    }

    load_user_groups(data) {
        data['loaded'] = true;
        this.setState(data, this.get_menu_item);
    }

    render() {
        let created_groups = [];
        let member_of_groups = [];

        for (var group of this.state.created_groups) {
            created_groups.push(<Group group={group} />);
        }

        let created_groups_display = (
            <div>
                <h3 style={{ marginTop: '50px' }}>My Groups</h3>
                <Button href="/add_group/" type="success">
                    Add Group
                </Button>
                {created_groups}
            </div>
        );

        for (var group of this.state.member_of_groups) {
            member_of_groups.push(<Group group={group} />);
        }

        let member_of_groups_display = null;
        if (member_of_groups.length > 0) {
            member_of_groups_display = (
                <div style={{ marginTop: '50px' }}>
                    <h4>Groups I'm in</h4>
                    {member_of_groups}
                </div>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    {created_groups_display}
                    {member_of_groups_display}
                </Container>
            </Loading>
        );
    }
}
