import React, { Component } from 'react';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Footer extends Component {
    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();
        let no_footer = false;

        let discover_background = 'white';
        let likes_background = 'white';
        let filter_background = 'white';
        let group_background = 'white';

        if (window.location.href.indexOf('menu_item_list') > -1) {
            discover_background = 'lightgray';
        } else if (window.location.href.indexOf('review_likes') > -1) {
            likes_background = 'lightgray';
        } else if (window.location.href.indexOf('filters') > -1) {
            filter_background = 'lightgray';
        } else if (window.location.href.indexOf('groups') > -1) {
            group_background = 'lightgray';
        } else {
            no_footer = true;
        }

        let footer = null;
        if (isMobile && !no_footer) {
            footer = (
                <div
                    className="row"
                    style={{
                        position: 'fixed',
                        height: '50px',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        fontSize: '30px',
                    }}
                >
                    <div
                        className="col-3"
                        style={{
                            borderRight: '1px solid gray',
                            backgroundColor: discover_background,
                        }}
                    >
                        <a className="nav-link" href="/menu_item_list/">
                            👀
                        </a>
                    </div>
                    <div
                        className="col-3"
                        style={{
                            borderRight: '1px solid gray',
                            backgroundColor: likes_background,
                        }}
                    >
                        <a className="nav-link" href="/review_likes/">
                            <i class="bi bi-heart"></i>
                        </a>
                    </div>
                    <div
                        className="col-3"
                        style={{
                            borderRight: '1px solid gray',
                            backgroundColor: filter_background,
                        }}
                    >
                        <a className="nav-link" href="/filters/">
                            <i class="bi bi-sliders"></i>
                        </a>
                    </div>
                    <div
                        className="col-3"
                        style={{ backgroundColor: group_background }}
                    >
                        <a className="nav-link" href="/user_groups/">
                            <i class="bi bi-people"></i>
                        </a>
                    </div>
                </div>
            );
        }

        return footer;
    }
}

export default Footer;
