import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper } from 'functions';
import { ItemViewer } from 'pages';

class Restaurant extends Component {
    render() {
        let restaurant = this.props.restaurant;

        let menu_items = [];

        for (let menu_item of restaurant.liked_items) {
            menu_items.push(
                <ItemViewer
                    menu_item={menu_item}
                    restaurant={null}
                    image_only={true}
                    liked={true}
                />,
            );
        }

        return (
            <div style={{ margin: '50px' }}>
                <h4>
                    <a href={'/restaurant_viewer/' + restaurant.id}>
                        {restaurant.name}
                    </a>
                </h4>
                <Button
                    href={restaurant.uber_eats_url}
                    target="_blank"
                    type="dark"
                >
                    Find On Uber Eats
                </Button>
                <div className="row">{menu_items}</div>
            </div>
        );
    }
}

export default class ReviewLikes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            restaurant_list: [],

            loaded: false,
        };

        this.get_liked_items = this.get_liked_items.bind(this);
        this.load_liked_items = this.load_liked_items.bind(this);
    }

    componentDidMount() {
        this.get_liked_items();
    }

    get_liked_items() {
        ajax_wrapper(
            'GET',
            '/api/restaurants/?user_id=' + window.secret_react_state.user.id,
            {},
            this.load_liked_items,
        );
    }

    load_liked_items(data) {
        this.setState({
            restaurant_list: data['restaurant_list'],
            loaded: true,
        });
    }

    render() {
        let restaurants = [];

        for (let restaurant of this.state.restaurant_list) {
            restaurants.push(<Restaurant restaurant={restaurant} />);
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div className="col-3"></div>
                    {restaurants}
                    <div className="col-3"></div>
                </Container>
            </Loading>
        );
    }
}
