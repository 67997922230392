import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    ItemViewer,
    Home,
    ReviewLikes,
    SwipeDiscovery,
    RestaurantViewer,
    Filters,
    InfiniteScrollDiscovery,
    UserGroups,
    AddGroup,
    ViewGroup,
    JoinGroup,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: 'privacy_policy',
                element: <PrivacyPolicy />,
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'login/:group_id',
                element: <Login />,
            },
            {
                path: 'sign_up',
                element: <SignUp />,
            },

            {
                path: 'sign_up/:group_id',
                element: <SignUp />,
            },
            {
                path: 'join_group/:group_id',
                element: <JoinGroup />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: 'menu_items',
                element: <SwipeDiscovery />,
            },
            {
                path: 'menu_item_list',
                element: <InfiniteScrollDiscovery />,
            },
            {
                path: 'menu_item_list/:group_id',
                element: <InfiniteScrollDiscovery />,
            },
            {
                path: 'review_likes',
                element: <ReviewLikes />,
            },
            {
                path: 'restaurant_viewer/:restaurant_id',
                element: <RestaurantViewer />,
            },
            {
                path: 'filters',
                element: <Filters />,
            },
            {
                path: 'user_groups',
                element: <UserGroups />,
            },
            {
                path: 'add_group',
                element: <AddGroup />,
            },
            {
                path: 'view_group/:group_id',
                element: <ViewGroup />,
            },
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [],
    },
];

export default routes;
