import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button, Alert } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';
import { ItemViewer } from 'pages';

export default class InfiniteScrollDiscovery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu_item_list: [],
            location: null,
            loaded: false,
            offset: 0,
            location_error: false,
        };

        this.get_current_location = this.get_current_location.bind(this);
        this.load_current_location = this.load_current_location.bind(this);
        this.get_menu_items = this.get_menu_items.bind(this);
        this.load_menu_items = this.load_menu_items.bind(this);
        this.swiped = this.swiped.bind(this);
    }

    componentDidMount() {
        this.get_current_location();
    }

    get_current_location() {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(
            this.load_current_location,
            this.load_current_location,
            options,
        );
    }

    load_current_location(pos) {
        if (pos.message && pos.message == 'User denied Geolocation') {
            this.setState({ location_error: true, loaded: true });
        } else {
            const crd = pos.coords;

            this.setState(
                {
                    location: pos.coords,
                },
                this.get_menu_items,
            );
        }
    }

    get_menu_items() {
        let group_id = get_global_state()['params']['group_id'];
        this.setState({ loaded: false });
        ajax_wrapper(
            'GET',
            '/api/get_menu_item_list/',
            {
                offset: this.state.offset,
                group_id: group_id,
                latitude: this.state.location.latitude,
                longitude: this.state.location.longitude,
            },
            this.load_menu_items,
        );
    }

    load_menu_items(data) {
        data['loaded'] = true;
        data['offset'] = this.state.offset + data['menu_item_list'].length;
        this.setState(data);
    }

    swiped() {
        console.log('Swiped');
    }

    render() {
        let menu_items = [];
        for (let menu_item of this.state.menu_item_list) {
            menu_items.push(
                <ItemViewer
                    menu_item={menu_item}
                    restaurant={menu_item.restaurant}
                    image_only={true}
                    swiped={this.swiped}
                />,
            );
        }

        let load_more = (
            <Button onClick={this.get_menu_items} type="primary">
                Load More...
            </Button>
        );
        if (menu_items.length < 102) {
            let group_id = get_global_state()['params']['group_id'];
            if (group_id) {
                load_more = (
                    <Button href="/menu_item_list" type="danger">
                        Explore Outside The Current Group Options
                    </Button>
                );
            } else if (this.state.location_error) {
                load_more = (
                    <Alert type="danger">
                        You've denied location permissions which is required to
                        use this site. You can{' '}
                        <a
                            href="https://support.google.com/chrome/answer/142065?hl=en"
                            target="_blank"
                        >
                            modify your settings using this guide.
                        </a>
                    </Alert>
                );
            } else {
                load_more = (
                    <Button href="/filters" type="danger">
                        No more options, try changing your filters.
                    </Button>
                );
            }
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div className="row">{menu_items}</div>
                    {load_more}
                    <div style={{ height: '80px' }}></div>
                </Container>
            </Loading>
        );
    }
}
