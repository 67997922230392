import React, { Component } from 'react';

import { Button } from 'library';
import { ajax_wrapper } from 'functions';

export default class ItemViewer extends Component {
    constructor(props) {
        super(props);

        let image_only = false;
        if (this.props.image_only) {
            image_only = true;
        }

        let liked = false;
        if (this.props.liked) {
            liked = this.props.liked;
        }

        this.state = {
            image_only: image_only,
            liked: liked,
            disliked: false,
        };

        this.like = this.like.bind(this);
        this.dislike = this.dislike.bind(this);
    }

    like() {
        ajax_wrapper(
            'POST',
            '/api/swipe/',
            {
                menu_item_id: this.props.menu_item.id,
                liked: true,
            },
            () => this.setState({ liked: true }), //this.props.swiped,
        );
    }

    dislike() {
        ajax_wrapper(
            'POST',
            '/api/swipe/',
            {
                menu_item_id: this.props.menu_item.id,
                liked: false,
            },
            () => this.setState({ disliked: true }),
        );
    }

    render() {
        let menu_item_display = null;
        if (this.props.menu_item.id) {
            let menu_item = this.props.menu_item;
            let liked_background = 'white';
            if (this.state.liked) {
                liked_background = 'lightgreen';
            }
            let disliked_background = 'white';
            if (this.state.disliked) {
                disliked_background = 'red';
            }

            let class_name = 'col-md-4 col-sm-12';
            if (this.props.className) {
                class_name = this.props.className;
            }
            if (this.state.image_only) {
                menu_item_display = (
                    <div className={class_name} style={{ padding: '3px' }}>
                        <div className="card">
                            <img
                                className="card-img-top"
                                src={menu_item.image}
                                style={{
                                    height: '276px',
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                }}
                            />
                            <div className="card-body row">
                                <div className="col-8">
                                    <h5 className="card-title">
                                        {menu_item.name}
                                    </h5>
                                    <h6>
                                        {menu_item.rating}%{' '}
                                        <i class="bi bi-hand-thumbs-up"></i> (
                                        {menu_item.num_reviewers} reviewers.)
                                    </h6>
                                </div>
                                <div className="col-2">
                                    <div
                                        style={{
                                            height: '40px',
                                            width: '40px',
                                            border: '1px solid black',
                                            borderRadius: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                image_only:
                                                    !this.state.image_only,
                                            })
                                        }
                                    >
                                        <a style={{ fontSize: '26px' }}>
                                            <i class="bi bi-list-ul"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div
                                        onClick={this.like}
                                        style={{
                                            height: '40px',
                                            width: '40px',
                                            border: '1px solid black',
                                            borderRadius: '20px',
                                            textAlign: 'center',
                                            backgroundColor: liked_background,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <img
                                            style={{
                                                height: '38px',
                                                display: 'black',
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                paddingTop: '2px',
                                            }}
                                            src="https://forgotten-maps-proc-gen.s3.us-west-2.amazonaws.com/Feast_Icon_95.png"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                let restaurant_display = null;
                let restaurant = this.props.restaurant;
                if (restaurant) {
                    restaurant_display = (
                        <div>
                            <div style={{ height: '50px' }}></div>
                            <h4 className="card-title">Restaurant</h4>
                            <h5>
                                <a href={'/restaurant_viewer/' + restaurant.id}>
                                    {restaurant.name}
                                </a>
                            </h5>
                            <h6>
                                {((restaurant.rating / 5) * 100).toFixed(0)}%{' '}
                                <i class="bi bi-hand-thumbs-up"></i> (
                                {restaurant.num_reviewers} reviewers.)
                            </h6>
                            <p>{restaurant.description}</p>
                        </div>
                    );
                }

                menu_item_display = (
                    <div
                        className={'col-md-4 col-sm-12'}
                        style={{ padding: '3px' }}
                    >
                        <div className="card">
                            <img
                                className="card-img-top"
                                src={menu_item.image}
                                style={{ width: 'auto', maxWidth: '600px' }}
                            />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-4">
                                        <div
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                                border: '1px solid black',
                                                borderRadius: '20px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    image_only:
                                                        !this.state.image_only,
                                                })
                                            }
                                        >
                                            <a style={{ fontSize: '26px' }}>
                                                <i class="bi bi-list-ul"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            onClick={this.dislike}
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                                border: '1px solid black',
                                                borderRadius: '20px',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    disliked_background,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <a style={{ fontSize: '26px' }}>
                                                🙁
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            onClick={this.like}
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                                border: '1px solid black',
                                                borderRadius: '20px',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    liked_background,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    height: '38px',
                                                    display: 'black',
                                                    marginRight: 'auto',
                                                    marginLeft: 'auto',
                                                    paddingTop: '2px',
                                                }}
                                                src="https://forgotten-maps-proc-gen.s3.us-west-2.amazonaws.com/Feast_Icon_95.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h4>
                                    {menu_item.name} ($
                                    {menu_item.price.toFixed(2)})
                                </h4>
                                <p>{menu_item.description}</p>
                                <h6>
                                    {menu_item.rating}%{' '}
                                    <i class="bi bi-hand-thumbs-up"></i> (
                                    {menu_item.num_reviewers} reviewers.)
                                </h6>
                                {restaurant_display}
                                <br />
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return menu_item_display;
    }
}
