import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button, Alert } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class GetGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            group: { members: [], creator: {}, name: '' },
            copied: false,
        };

        this.get_group = this.get_group.bind(this);
        this.load_group = this.load_group.bind(this);
        this.join_group = this.join_group.bind(this);
    }

    componentDidMount() {
        this.get_group();
    }

    get_group() {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'GET',
            '/api/get_group/',
            { id: params['group_id'] },
            this.load_group,
        );
    }

    load_group(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    join_group() {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'POST',
            '/api/join_group/',
            { group_id: params['group_id'] },
            () => (window.location.href = `/view_group/${params['group_id']}/`),
        );
    }

    render() {
        let params = get_global_state()['params'];
        let group_id = params['group_id'];

        let user = window.secret_react_state.user;
        let invite = null;
        if (user) {
            invite = (
                <Button type="success" onClick={this.join_group}>
                    Join Group
                </Button>
            );
        } else {
            invite = (
                <p>
                    Looks like you need to{' '}
                    <a href={`/login/${group_id}/`}>login</a> or{' '}
                    <a href={`/sign_up/${group_id}/`}>sign up</a> to accept this
                    invite.
                </p>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div>
                        <h4>
                            You've been invited to join {this.state.group.name}{' '}
                            by {this.state.group.creator.email}
                        </h4>
                        {invite}
                    </div>
                </Container>
            </Loading>
        );
    }
}
