import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';

import { Loading, Container, Button } from 'library';
import { ItemViewer } from 'pages';

export default class RestaurantViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            restaurant: {},

            loaded: false,
        };

        this.get_restaurant = this.get_restaurant.bind(this);
        this.load_restaurant = this.load_restaurant.bind(this);
    }

    componentDidMount() {
        this.get_restaurant();
    }

    get_restaurant() {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'GET',
            '/api/get_restaurant/?restaurant_id=' + params['restaurant_id'],
            {},
            this.load_restaurant,
        );
    }

    load_restaurant(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let restaurant_display = null;
        let menu_items = [];

        if (this.state.restaurant.id) {
            let restaurant = this.state.restaurant;
            restaurant_display = (
                <div>
                    <h5>{restaurant.name}</h5>
                    <h6>
                        {((restaurant.rating / 5) * 100).toFixed(0)}%{' '}
                        <i class="bi bi-hand-thumbs-up"></i> (
                        {restaurant.num_reviewers} reviewers.)
                    </h6>
                    <p>{restaurant.description}</p>
                </div>
            );

            for (var item of restaurant.menu_items) {
                menu_items.push(
                    <ItemViewer
                        menu_item={item}
                        restaurant={null}
                        swiped={console.log}
                    />,
                );
            }
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    {restaurant_display}
                    <div className="row">{menu_items}</div>
                </Container>
            </Loading>
        );
    }
}
