import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button, TextInput, Form } from 'library';
import { ajax_wrapper } from 'functions';

export default class AddGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            loaded: true,
        };

        this.add_group = this.add_group.bind(this);
    }

    add_group(data) {
        ajax_wrapper(
            'POST',
            '/api/add_group/',
            { name: data['name'] },
            () => (window.location.href = '/user_groups/'),
        );
    }

    render() {
        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <h4>New Group</h4>
                    <Form submit={this.add_group} submit_text="Create Group">
                        <TextInput name="name" label="Group Name" />
                    </Form>
                </Container>
            </Loading>
        );
    }
}
