import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper } from 'functions';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import {
    APIProvider,
    Map,
    MapCameraChangedEvent,
    Marker,
} from '@vis.gl/react-google-maps';

class Category extends Component {
    constructor(props) {
        super(props);

        this.select_category = this.select_category.bind(this);
    }

    select_category() {
        this.props.select_category(this.props.category.id);
    }
    render() {
        let button_type = 'danger';
        if (
            this.props.categories_selected.indexOf(this.props.category.id) > -1
        ) {
            button_type = 'success';
        }

        return (
            <Button type={button_type} onClick={this.select_category}>
                {this.props.category.name}
            </Button>
        );
    }
}

export default class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,

            distance: 1,
            price: 5,
            categories_selected: [],
            categories: [],
            location: { latitude: 40.7128, longitude: -74.006 },
            use_current_location: false,
            map_key: 1,
        };

        this.get_current_location = this.get_current_location.bind(this);
        this.load_current_location = this.load_current_location.bind(this);
        this.update_position = this.update_position.bind(this);
        this.update_distance = this.update_distance.bind(this);
        this.update_price = this.update_price.bind(this);
        this.get_categories = this.get_categories.bind(this);
        this.load_categories = this.load_categories.bind(this);
        this.select_category = this.select_category.bind(this);
        this.save_filters = this.save_filters.bind(this);
        this.get_user_filters = this.get_user_filters.bind(this);
        this.load_user_filters = this.load_user_filters.bind(this);
        this.use_map_location = this.use_map_location.bind(this);
    }

    componentDidMount() {
        this.get_user_filters();
        this.get_categories();
    }

    get_current_location() {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(
            this.load_current_location,
            console.log,
            options,
        );
    }

    load_current_location(pos) {
        const crd = pos.coords;

        this.setState(
            {
                location: pos.coords,
                use_current_location: true,
                map_key: this.state.map_key + 1,
            },
            this.save_filters,
        );
    }

    update_position(data) {
        console.log('Data', data);

        this.setState({
            location: {
                latitude: data.detail.center.lat,
                longitude: data.detail.center.lng,
            },
            use_current_location: false,
        });
    }

    update_distance(data) {
        this.setState({ distance: data[1] }, this.save_filters);
    }

    update_price(data) {
        this.setState({ price: data[1] }, this.save_filters);
    }

    select_category(id) {
        let index = this.state.categories_selected.indexOf(id);
        let categories_selected = this.state.categories_selected;
        if (index == -1) {
            categories_selected.push(id);
        } else {
            categories_selected.splice(index, 1);
        }

        this.setState(
            { categories_selected: categories_selected },
            this.save_filters,
        );
    }

    get_categories() {
        ajax_wrapper('GET', '/api/get_categories/', {}, this.load_categories);
    }

    load_categories(data) {
        this.setState(data);
    }

    save_filters() {
        ajax_wrapper(
            'POST',
            '/user/save_filters/',
            {
                filters: {
                    distance: this.state.distance,
                    price: this.state.price,
                    categories_selected: this.state.categories_selected,
                    use_current_location: this.state.use_current_location,
                    location: this.state.location,
                },
            },
            console.log,
        );
    }

    get_user_filters() {
        ajax_wrapper(
            'GET',
            '/user/get_user_filters/',
            {},
            this.load_user_filters,
        );
    }

    load_user_filters(data) {
        data['map_key'] = this.state.map_key + 1;
        this.setState(data);
    }

    use_map_location() {
        this.setState({ use_current_location: false }, this.save_filters);
    }

    render() {
        let categories = [];
        for (let category of this.state.categories) {
            categories.push(
                <Category
                    category={category}
                    categories_selected={this.state.categories_selected}
                    select_category={this.select_category}
                />,
            );
        }

        let center = { latitude: 39.7643389, longitude: -104.8551114 };

        if (this.state.location) {
            center = {
                lat: this.state.location.latitude,
                lng: this.state.location.longitude,
            };
        }

        let location_filter = (
            <div>
                <p>
                    Put the location you want to search in the center or select
                    Use Current Location To Always Use Your Current Location
                </p>
                <Button type="success" onClick={this.get_current_location}>
                    Use Current Location
                </Button>

                <p>OR</p>

                <APIProvider
                    apiKey={'AIzaSyBYsFM2JH2iD6LlKQl6DJixoukcnhLmy_o'}
                    onLoad={() => console.log('Maps API has loaded.')}
                >
                    <Map
                        key={'map_' + this.state.map_key}
                        defaultZoom={13}
                        defaultCenter={center}
                        onCameraChanged={this.update_position}
                    >
                        <Marker position={center} key={center.latitude} />
                    </Map>
                </APIProvider>
                <Button type="success" onClick={this.use_map_location}>
                    Use The Location On The Map
                </Button>
            </div>
        );

        let filters = (
            <div>
                <h3 style={{ marginTop: '50px' }}>
                    Filter the food you discover!
                </h3>
                <p>How far away can the restaurant be?</p>
                <p>Current: Less than {this.state.distance} mile(s).</p>

                <RangeSlider
                    value={[0, this.state.distance]}
                    min={1}
                    max={50}
                    step={1}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    onInput={this.update_distance}
                />

                <div style={{ height: '40px' }}></div>
                <p>
                    What is the maximum price you are willing to pay for a
                    single item?
                </p>
                <p>Current: ${this.state.price}</p>
                <RangeSlider
                    value={[0, this.state.price]}
                    min={5}
                    max={100}
                    step={5}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    onInput={this.update_price}
                />
                <div style={{ height: '40px' }}></div>
                <p>
                    Pick as many or as few interests you want! If you pick 0, we
                    will give you everything!
                </p>
                {categories}
            </div>
        );

        return (
            <Loading loaded={this.state.loaded}>
                <Container>{filters}</Container>
            </Loading>
        );
    }
}
