import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper } from 'functions';
import { ItemViewer } from 'pages';

export default class SwipeDiscovery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu_item: {},
            restaurant: {},
            menu_item_list: [],

            loaded: false,
            offset: -1,
        };

        this.get_menu_item = this.get_menu_item.bind(this);
        this.load_menu_item = this.load_menu_item.bind(this);
        this.get_swipe_count = this.get_swipe_count.bind(this);
        this.load_swipe_count = this.load_swipe_count.bind(this);
        this.swiped = this.swiped.bind(this);
    }

    componentDidMount() {
        this.get_swipe_count();
    }

    get_swipe_count() {
        ajax_wrapper('GET', '/api/get_swipe_count/', {}, this.load_swipe_count);
    }

    load_swipe_count(data) {
        this.setState({ offset: data['count'] }, this.get_menu_item);
    }

    get_menu_item() {
        this.setState({ loaded: false });
        ajax_wrapper(
            'GET',
            '/api/get_menu_items/',
            { offset: this.state.offset },
            this.load_menu_item,
        );
    }

    load_menu_item(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    swiped() {
        this.setState({ offset: this.state.offset + 1 }, this.get_menu_item);
    }

    render() {
        let menu_item = null;
        if (this.state.menu_item.id) {
            menu_item = (
                <ItemViewer
                    menu_item={this.state.menu_item}
                    restaurant={this.state.restaurant}
                    full={true}
                    swiped={this.swiped}
                />
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>{menu_item}</Container>
            </Loading>
        );
    }
}
