import React, { Component } from 'react';

import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

export default class Home extends Component {
    render() {
        if (window.secret_react_state.user) {
            window.location.href = '/menu_item_list/';
        }

        return (
            <Container>
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-sm-12">
                        <h3>Welcome to Feast! </h3>
                        <p>Eat With Your Eyes and Find Your Next Meal.</p>

                        <p>
                            Feast is an app where you can qucikly browse your
                            local restaurants to find your next meal. You'll be
                            presented with pictures of meals from your area and
                            you can swipe right (YUM!) to keep save it, order it
                            right now or get it the next time you go out to eat.
                        </p>
                        <p>
                            From there, you can invite your friends to find
                            dishes they want at the restaurants you've liked.
                            Making it easy to discover where to go, whether it
                            be your significant other, or a large group of
                            friends or colleagues.
                        </p>

                        <Button href="/sign_up" type="primary" target="_blank">
                            Sign Up
                        </Button>
                        <div style={{ height: '20px' }}></div>
                        <Button href="/login" type="success" target="_blank">
                            Log In
                        </Button>
                        <div>
                            <img src="https://forgotten-maps-proc-gen.s3.us-west-2.amazonaws.com/Feast_home.png" />
                        </div>

                        <div style={{ height: '20px' }}></div>
                        <a
                            href="/privacy_policy"
                            style={{ color: 'dodgerblue' }}
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </Container>
        );
    }
}
