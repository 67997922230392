import React, { Component } from 'react';

import { Image, Button } from 'library';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();

        let navbar_collapse = 'collapse navbar-collapse';
        let navbar_toggle = (
            <button
                class="navbar-toggler"
                type="button"
                onClick={() => this.setState({ show: !this.state.show })}
            >
                <span class="navbar-toggler-icon"></span>
            </button>
        );

        let items = [];

        if (!is_logged_in) {
        }

        if (is_staff) {
            // Add staff routes to header
            items.push();
        }

        if (is_logged_in) {
            // Add authed routes to header
            items.push(
                <li className="nav-item">
                    <a className="nav-link" href="/menu_item_list/">
                        Discover
                    </a>
                </li>,
                <li className="nav-item">
                    <a className="nav-link" href="/review_likes/">
                        Likes
                    </a>
                </li>,
                <li className="nav-item">
                    <a className="nav-link" href="/filters/">
                        Filters
                    </a>
                </li>,
                <li className="nav-item">
                    <a className="nav-link" href="/user_groups/">
                        Groups
                    </a>
                </li>,
                <li className="nav-item">
                    <a className="nav-link" href="/logout/">
                        Logout
                    </a>
                </li>,
            );
        }

        return (
            <nav className="navbar navbar-fixed-top" style={{ zIndex: 100 }}>
                <a className="navbar-brand" href="/" style={{ padding: '0px' }}>
                    <Image
                        src="https://forgotten-maps-proc-gen.s3.us-west-2.amazonaws.com/Feast_Icon_95.png"
                        style={{ maxHeight: '50px' }}
                    />
                    Feast
                </a>
                {navbar_toggle}
                <div
                    class={`${navbar_collapse} ${
                        this.state.show ? 'show' : ''
                    }`}
                    style={{
                        maxHeight: `${
                            navbar_toggle
                                ? this.state.show
                                    ? '250px'
                                    : '0px'
                                : 'auto'
                        }`,
                        transition: 'max-height .35s ease',
                        overflow: 'hidden',
                        display: 'flex',
                        flexGrow: '1',
                    }}
                >
                    <ul className="navbar-nav" style={{ marginLeft: 'auto' }}>
                        {items}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Header;
